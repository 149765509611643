import http from './http'

// 账户密码登录
export const api_login = (data) => {
    return http({
        url: '/store-service/login',
        method: 'post',
        data
    })
}
// 发短信验证码
export const api_sendCode = (data) => {
    return http({
        url: '/store-service/sendCode',
        method: 'post',
        data
    })
}
// 验证码登录
export const api_codeLogin = (data) => {
    return http({
        url: '/store-service/codeLogin',
        method: 'post',
        data
    })
}
// 修改/新增密码
export const api_resetPassword = (data) => {
    return http({
        url: '/store-service/resetPassword',
        method: 'post',
        data
    })
}
// 账户信息
export const api_user_info = (params) => {
    return http({
        url: '/store-service/profile',
        method: 'get',
        params
    })
}
// 忘记密码
export const api_forgotPassword = (data) => {
    return http({
        url: '/store-service/forgot_password',
        method: 'post',
        data
    })
}


