// 手机验证
export const checkAccount = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入手机号"));
  } else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(value)) {
    callback(new Error("手机号格式有误"));
  } else {
    callback();
  }
};

// 验证码
export const checkYzm = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入验证码"));
  } else {
    callback()
  }
};
// 密码验证
export const checkPwd = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入密码"));
  } else {
    callback();
  }
};




