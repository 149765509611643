import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login"
import Layout from "@/views/Layout"
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  // {
  //   path: '/reset',
  //   component: () => import('@/views/login/reset.vue')
  // },
  // 首页
  {
    path: '/home',
    meta: { title: '首页' },
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/Layout/home')
      },
      {
        path: '/home/user',
        meta: { title: '账户信息' },
        component: () => import('@/views/Layout/home/user.vue')
      },
      {
        path: '/home/modifyPassword',
        meta: { title: '账号密码' },
        component: () => import('@/views/Layout/home/modifyPassword.vue')
      }
    ]
  },
  // 达人管理
  // {
  //   path: '/master',
  //   redirect: '/master/list',
  //   meta: { title: '达人管理' },
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/master/list',
  //       meta: { title: '达人信息' },
  //       component: () => import('@/views/Layout/master/list.vue')
  //     },
  //   ]
  // },
  // 任务管理
  {
    path: '/task',
    redirect: '/task/cloud',
    meta: { title: '任务管理' },
    component: Layout,
    children: [
      {
        path: '/task/orders',
        meta: { title: '全部订单' },
        component: () => import('@/views/Layout/task/orders.vue')
      },
      {
        path: '/task/clearRecord',
        meta: { title: '取消记录' },
        component: () => import('@/views/Layout/task/clearRecord.vue')
      },
      {
        path: '/task/scanCode',
        meta: { title: '扫码直发' },
        component: { render(c) { return c('router-view') } },
        children: [
          {
            path: '/task/scanCode',
            component: () => import('@/views/Layout/task/scanCode.vue')
          },
          {
            path: '/task/scanCode/scanCodePulish',
            meta: { title: '扫码直发任务' },
            component: () => import('@/views/Layout/task/scanCodePulish.vue'),
            hidden: true
          },
          {
            path: '/task/scanCode/scanCodeDetail',
            meta: { title: '扫码直发详情' },
            component: () => import('@/views/Layout/task/scanCodeDetail.vue'),
            hidden: true
          }
        ]
      },
      {
        path: '/task/cloud',
        meta: { title: '云探任务' },
        component: { render(c) { return c('router-view') } },
        redirect: '/task/cloud',
        children: [
          {
            path: '/task/cloud',
            component: () => import('@/views/Layout/task/cloud.vue')
          },
          {
            path: '/task/cloud/cloudPulish',
            meta: { title: '云探任务发布' },
            component: () => import('@/views/Layout/task/cloudPulish.vue'),
            hidden: true
          },
          {
            path: '/task/cloud/cloudDetail',
            meta: { title: '云探任务详情' },
            component: () => import('@/views/Layout/task/cloudDetail.vue'),
            hidden: true

          }
        ]
      },
      {
        path: '/task/visit',
        meta: { title: '实探任务' },
        component: { render(c) { return c('router-view') } },
        children: [
          {
            path: '/task/visit',
            component: () => import('@/views/Layout/task/visit.vue')
          },
          {
            path: '/task/visit/visitPulish',
            meta: { title: '实探任务发布' },
            component: () => import('@/views/Layout/task/visitPulish.vue'),
            hidden: true
          },
          {
            path: '/task/visit/visitDetail',
            meta: { title: '实探任务详情' },
            component: () => import('@/views/Layout/task/visitDetail.vue'),
            hidden: true
          }
        ]
      },
      {
        path: '/task/officeVisit',
        meta: { title: '线下实探任务' },
        component: { render(c) { return c('router-view') } },
        children: [
          {
            path: '/task/officeVisit',
            component: () => import('@/views/Layout/task/officeVisit.vue')
          },
          {
            path: '/task/officeVisit/officePulish',
            meta: { title: '线下实探任务发布' },
            component: () => import('@/views/Layout/task/officePulish.vue'),
            hidden: true
          },
          {
            path: '/task/officeVisit/officeVisitDetail',
            meta: { title: '实探任务详情' },
            component: () => import('@/views/Layout/task/officeVisitDetail.vue'),
            hidden: true
          }
        ]
      }
    ]
  },

  //查询管理
  {
    path: '/query',
    redirect: '/query/report',
    meta: { title: '查询管理' },
    component: Layout,
    children: [
      {
        path: '/query/report',
        meta: { title: '任务报表' },
        component: () => import('@/views/Layout/query/report.vue')
      },
      {
        path: '/query/transaction',
        meta: { title: '交易明细' },
        component: () => import('@/views/Layout/query/transaction.vue')
      },
      {
        path: '/query/team',
        meta: { title: '我的团队' },
        component: () => import('@/views/Layout/query/team.vue')
      },

    ]
  },
  // 容错页
  {
    path: '/*',
    component: () => import('@/Error.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
