import goHttp from './goHttp'
// 账户密码登录
export const api_login = (data) => {
    return goHttp({
        url: '/user-api/api/user/login/account',
        method: 'post',
        data
    })
}
// 发短信验证码
export const api_sendCode = (data) => {
    return goHttp({
        url: '/common-api/api/sms/send',
        method: 'post',
        data
    })
}
// 验证码登录
export const api_codeLogin = (data) => {
    return goHttp({
        url: 'user-api/api/user/login/code',
        method: 'post',
        data
    })
}
// 设置密码
export const api_resetPassword = (data) => {
    return goHttp({
        url: '/user-api/api/user/setPassword',
        method: 'post',
        data
    })
}
// 修改密码
export const api_modifyPassword = (data) => {
    return goHttp({
        url: '/user-api/api/user/modifyPassword',
        method: 'post',
        data
    })
}
// 忘记密码
export const api_forgotPassword = (data) => {
    return goHttp({
        url: '/user-api/api/user/updatePassword',
        method: 'post',
        data
    })
}