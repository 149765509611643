<template>
  <div class="layout">
    <el-container>
      <el-aside width="220px">
        <div class="top">
          <div class="img">
            <img
              src="http://store-tiktok.oss-cn-beijing.aliyuncs.com/mini/images/1680140741992149.png"
              alt=""
            />
            <!-- <img src="" alt="" :class="collapse ? 'hide' : ''" /> -->
          </div>
          <h2>二当家</h2>
          <!-- <i
            style="font-size: 20px"
            :class="collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            @click="handleCollapse"
          ></i> -->
        </div>
        <el-menu
          unique-opened
          router
          :default-active="defactive"
          class="el-menu-vertical-demo"
          active-text-color="#F16773"
          text-color="#fff"
        >
          <el-menu-item class="home" index="/home">
            <i class="el-icon-s-home"></i>

            <span slot="title">首页</span>
          </el-menu-item>
          <!-- <el-submenu index="/master">
            <template slot="title">
              <i class="el-icon-user"></i>
              <span>达人管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/master/list">达人信息</el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->
          <el-submenu index="/task">
            <template slot="title">
              <i class="el-icon-tickets"></i>
              <span>任务管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/task/orders">全部订单</el-menu-item>
              <el-menu-item index="/task/clearRecord">取消记录</el-menu-item>
              <el-menu-item index="/task/cloud">云探任务</el-menu-item>
              <el-menu-item index="/task/visit">实探任务</el-menu-item>
              <el-menu-item index="/task/officeVisit"
                >线下实探任务</el-menu-item
              >
              <el-menu-item index="/task/scanCode">扫码直发</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="/query">
            <template slot="title">
              <i class="el-icon-pie-chart"></i>
              <span>查询管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/query/report">任务报表</el-menu-item>
              <el-menu-item index="/query/transaction">交易明细</el-menu-item>
              <el-menu-item index="/query/team">我的团队</el-menu-item>
              <!-- <el-menu-item index="/query/user">账户信息</el-menu-item> -->
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(v, i) in bread" :key="i" :to="v.to">{{
              v.title
            }}</el-breadcrumb-item>
          </el-breadcrumb>

          <div class="right">
            <el-avatar :size="42" :src="avatar"></el-avatar>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="account">
                  <div style="display: flex; align-items: center">
                    <img
                      style="width: 24px; height: 24px"
                      src="../../static/img/personal.png"
                      alt=""
                    />
                    <div
                      style="
                        font-size: 14px;
                        color: #666666;
                        font-weight: bold;
                        margin-left: 9px;
                      "
                    >
                      个人账户
                    </div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="modify">
                  <div style="display: flex; align-items: center">
                    <img
                      style="width: 24px; height: 24px"
                      src="../../static/img/password.png"
                      alt=""
                    />
                    <div
                      style="
                        font-size: 14px;
                        color: #666666;
                        font-weight: bold;
                        margin-left: 9px;
                      "
                    >
                      设置密码
                    </div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="logout">
                  <div style="display: flex; align-items: center">
                    <img
                      style="width: 24px; height: 24px"
                      src="../../static/img/exit.png"
                      alt=""
                    />
                    <div
                      style="
                        font-size: 14px;
                        color: #666666;
                        font-weight: bold;
                        margin-left: 9px;
                      "
                    >
                      退出登录
                    </div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main
          ><!-- 二级出口 -->
          <router-view></router-view>
          <!-- 三级出口 -->
          <!-- <router-view></router-view> -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { api_user_info } from "@/api/user";
export default {
  data() {
    return {
      collapse: false,
      bread: [],
      avatar:
        "http://store-tiktok.oss-cn-beijing.aliyuncs.com/mini/images/1680140741992149.png",
      name: "",
    };
  },
  created() {
    this.callbread();
    this.getAccount();
  },
  watch: {
    $route() {
      this.callbread();
    },
  },
  computed: {
    defactive() {
      const routerPath = this.$route.path;
      const routerList = routerPath.split("/");
      if (routerList.length == 4) {
        routerList.pop();
        return routerList.join("/");
      } else {
        return routerPath;
      }
    },
  },
  methods: {
    // 展开和收缩
    handleCollapse() {
      this.collapse = !this.collapse;
    },
    // 面包屑
    callbread() {
      let r = this.$route.matched.filter((v) => {
        return v && v.meta && v.meta.title;
      });
      let arr = r.map((v) => {
        return { title: v.meta.title, to: v.path };
      });
      this.bread = [...arr];
    },
    // 获取账户
    getAccount() {
      api_user_info().then((res) => {
        if (res.data.code == 200) {
          this.name = res.data.data.contact;
        }
      });
    },
    // 面包屑上面的下拉
    handleCommand(type) {
      if (type == "logout") {
        localStorage.removeItem("tk");
        localStorage.removeItem("JWToken");
        this.$router.push("/login");
      } else if (type == "account") {
        this.$router.push("/home/user");
      } else {
        this.$router.push("/home/modifyPassword");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  height: 100%;
  background-color: #edf3f3;

  .el-container .el-aside .el-menu .el-menu-item.is-active {
    width: 220px;
    // height: 64px;
    background: #f3f4ff;
    box-shadow: 0px 20px 50px 0px rgba(191, 21, 108, 0.05);
    border-radius: 30px 0px 0px 30px;
    box-sizing: border-box;
  }

  .el-container {
    overflow: hidden;

    i {
      color: #fff;
    }

    .hide {
      display: none;
    }

    .el-header {
      padding: 0;
      display: flex;
      background-color: #fff;
      margin-bottom: 3px;

      .title {
        display: flex;
        align-items: center;
        margin-right: 20px;

        img {
          width: 44px;
          height: 44px;
        }

        p {
          font-size: 22px;
          color: #333333;
        }
      }

      .el-breadcrumb {
        margin-left: 20px;
        flex: 1;
        display: flex;
        align-items: center;

        i {
          font-size: 20px;
          margin-right: 20px;
        }

        /deep/ .el-breadcrumb__separator {
          color: #113ffb;
        }
      }

      .right {
        display: flex;
        align-items: center;
        margin-right: 30px;

        i {
          font-size: 20px;
          color: #666666;
        }

        .el-avatar--circle {
          margin-right: 24px;
        }

        .el-dropdown {
          i {
            margin-left: 10px;
          }
        }
      }
    }

    height: 100%;

    .el-aside {
      height: 100%;
      background: linear-gradient(42deg, #4572ff 0%, #4289ff 100%) !important;

      .top {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin: 25px 0 30px 0;

        .img {
          width: 60px;
          height: 60px;
          background: #ffffff;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;

          img {
            padding-left: 5px;
          }
        }

        h2 {
          color: #fff;
          letter-spacing: 3.2px;
        }
      }

      .el-menu {
        border: none;
        border-right: none;
        background: transparent;

        .el-submenu {
          margin-bottom: 20px;

          .img {
            width: 26px;
            height: 26px;
          }
        }

        /deep/ .el-submenu .el-menu {
          background: transparent;
        }

        .el-submenu__title * {
          font-size: 18px;
          margin-left: 14px;
        }

        /deep/ .el-submenu__title {
          // height: 64px;
          letter-spacing: 1.2px;
        }

        /deep/ .el-submenu__title:hover {
          background: rgba(0, 0, 0, 0.2);
          width: 220px;
          box-shadow: 0px 20px 50px 0px rgba(191, 21, 108, 0.05);
          border-radius: 30px 0px 0px 30px;
        }

        /deep/ .el-submenu__title i {
          color: #fff !important;
        }

        .el-menu-item {
          min-width: none;
          padding-left: 90px !important;
          text-align: left;

          &.home {
            margin-bottom: 20px;
            font-size: 18px;
            // height: 64px;
            padding-left: 34px !important;
            text-align: left;
            letter-spacing: 2px;

            i {
              font-size: 26px;
              margin-right: 26px;
            }

            &:hover {
              color: #fff !important;
            }

            // &.is-active {
            //   color: #113ffb !important;
            // }
          }
        }

        .el-menu-item:hover {
          width: 220px;
          background: rgba(0, 0, 0, 0.3) !important;
          box-shadow: 0px 20px 50px 0px rgba(191, 21, 108, 0.05);
          border-radius: 30px 0px 0px 30px;
        }

        .el-menu-item.is-active {
          background: #fff !important;
        }

        .el-menu-item.is-active i {
          color: #ff606e !important;
        }
      }
    }

    .el-main {
      background: #f3f4ff;
      overflow: auto;
      padding: 20px 20px 0 20px;
    }
  }
}
</style>