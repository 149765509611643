// 引入axios
import axios from 'axios'
// 引入elementui的Message
import { Message } from 'element-ui'
import router from '@/router'
// 配置服务器地址
axios.defaults.baseURL = process.env.VUE_APP_API_URL

// axios.defaults.baseURL = 'http://192.168.110.189/api' //dbm本地
// axios.defaults.baseURL = 'https://assignment-api.huabanshou.cn/api' //线上
// axios.defaults.baseURL = 'https://assignment-store.huabanshou.cn/api' //测试s
// axios.defaults.baseURL = 'http://assignment-two-mer.test/api' //本地

// 超时时间
axios.defaults.timeout = 1000000

// 请求拦截器
axios.interceptors.request.use(config => {
    // 设置token
    const token = localStorage.getItem('tk')
    // const token = '44tPDnjlgLEJM2HB5CkdQg==.Z68CNaDbjeDuGS9tyuNpb6hO8cu83RiBbDYW3oKceg+TxcIlu74A7T2K/lGAoO03lXpMlbPBf9ujCasugeYkaNX2jlnVxnykQl+Cf2tlMCI='
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
        config.headers.business = 'admin_merchat';
        config.headers.version = '1.0.4';

    }
    return config
}, err => {
    return Promise.reject(err)
})
// 响应拦截器
axios.interceptors.response.use(res => {
    if (res.data.code == 202) {
        Message.error(res.data.msg)
    }
    if (res.data.code == 422) {
        Message.error(res.data.msg)
    }
    if (res.data.code == 403) {
        Message.error(res.data.msg)
    }
    if (res.data.code == 401) {
        localStorage.removeItem('tk')
        Message.error(res.data.msg);
        router.push('/')
    }
    if (res.data.code == 500) {
        Message.error('哎呀，服务器出了点小问题~');
    }
    return res
}, err => {
    // console.log(123, err);
    if (err.code == 'ECONNABORTED') {
        Message.error('请求超时')
    }
    if (err.code == 'ERR_NETWORK') {
        Message.error(err.Message)
    }
    return Promise.reject(err)
})

export default axios