import Vue from 'vue'
import ElementUI from 'element-ui';
import "@/static/style/common.css"
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
Vue.use(ElementUI);
// event Bus 用于无关系组件间的通信。
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false

window._AMapSecurityConfig = {
  securityJsCode: '48d4c50449657c2eccaec50ad4a7e774',
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
