<template>
  <div class="app">
    <!-- 一级出口 -->
    <router-view></router-view>
  </div>
</template>

<script >
export default {};
</script>

<style lang="less" scoped>
.app {
  height: 100%;
  width: 100%;
}
</style>